html {
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    padding: 0;

    background: #242432;
    color: white;

    font-size: 26px;
}

@media screen and (max-width: 600px) {
    body {
        font-size: 4.6vw;
    }
}

* {
    font-family: 'Varela Round', 'Ubuntu', 'Roboto', sans-serif;
    line-height: 1.2;
    letter-spacing: 0.035em;
    box-sizing: border-box;
}

.material-icons {
    user-select: none;
    font-size: 1em;
}

header .material-icons {
    font-size: 1.5em;
}

button {
    background: #0000;
    outline: none;
    border: none;
    cursor: pointer;
}

h1 {
    font-size: 1.7em;
    margin: 0;
}

a {
    text-decoration: none;
    color: white;
}
